<template>
  <div class="corp-word">
    <div class="search-header">
      <a-form layout="inline"
              :model="searchForm">
        <a-form-item>
          <a-input-search v-model:value="searchForm.title"
                          placeholder="请输入话术标题或内容"
                          style="width: 360px"
                          @keydown.enter="onSearchSubmit"
                          v-debounce="onSearchSubmit" />
        </a-form-item>
      </a-form>
      <router-link :to="{name:'corpWord_create'}">
        <a-button type="primary"
                  centered>
          <svg-icon type="icontianjia1" />新建话术
        </a-button>
      </router-link>
    </div>

    <div class="flex">
      <div class="table-sider">
        <a-menu v-model:selectedKeys="selectedKeys"
                mode="inline"
                @select="codeGroupClick">
          <div class="table-sider__add"
               @click.stop="isInputShow = !isInputShow">
            <svg-icon class="mr-8"
                      type="icontianjia1" />
            添加分组
          </div>
          <a-input v-model:value="groupName"
                   v-show="isInputShow"
                   class="table-sider__add-input"
                   placeholder="请输入分组"
                   @click.stop="isInputShow = true"
                   @keyup.enter="createCodeGroup"
                   @blur="createCodeGroup" />

          <a-menu-item class="table-sider__item"
                       key="">
            全部
            <div class="flex-shrink-0">
              {{ totalCodeGroupNum }}
              <svg-icon class="table-sider__icon opacity-0"
                        type="iconmore01" />
            </div>
          </a-menu-item>

          <a-menu-item v-for="Group in corpWordsGroup"
                       :key="Group.id"
                       class="table-sider__item">
            <div class="truncate"
                 :title='Group.name'>{{Group.name}}</div>

            <div class="flex-shrink-0 ml-8">
              {{Group.num}}
              <a-dropdown>
                <svg-icon class="table-sider__icon"
                          type="iconmore01" />
                <template #overlay>
                  <a-menu>
                    <a-menu-item @click="editGroup = Group; editVisible = true">
                      编辑
                    </a-menu-item>
                    <a-menu-item @click="deleteCodeGroup(Group)">
                      删除
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </a-menu-item>
        </a-menu>
      </div>
      <a-table :columns="columns"
               :data-source="corpWord"
               row-key="id"
               :scroll="{ x: '100%', y: 'calc(100vh - 322px)' }"
               :pagination="pagination"
               @change="onPaginationChange"
               :loading="tableLoading">
        <template #wordTitle="{record}">
          <span>{{ record.title }}</span>
        </template>

        <template #content="{ record, index }">
          <div class="word-content"
               :class="{hidden: !expandedArr[index] && i > 1}"
               v-for="(content, i) in record.contents"
               :key="i">
            <span class="word-text"
                  v-if="content.type === 'text'">{{content.content}}</span>
            <div class="md-camera"
                 v-if="content.type === 'img'">
              <a-image class="word-img"
                       :src="content.bucketUri"
                       alt="话术图片" />
            </div>

            <div class="msg-link"
                 v-if="content.type === 'link'"
                 @click="goLinkUrl(content, 'link')">
              <div class="link-text">
                <p class="title">{{content.title}}</p>
                <p class="abstract">{{content.summary}}</p>
              </div>
              <img class="link-img"
                   :src="content.cover"
                   alt="">
            </div>

            <template v-if="content.type === 'file'">
              <div class="msg-link"
                   @click="goLinkUrl(content, 'file')">
                <div class="link-text">
                  <p class="title">{{content.name}}</p>
                  <p class="abstract">{{renderSize(content.size)}}</p>
                </div>
                <img class="link-img"
                     :src="pdfImgUrl"
                     alt="">
              </div>
            </template>
          </div>
          <div class="expand__container"
               @click="clickExpend(index)">
            <svg-icon v-if="record.contents.length > 2"
                      class="iconshouqi"
                      :class="{'icon-open': expandedArr[index]}"
                      type="iconshouqi" />
            <span>共{{record.contents.length}}条</span>
          </div>

        </template>

        <template #createdUserWorkWechatThirdAppUserId="{ text }">
          <div v-is="'ww-open-data'"
               class="ww-open-data"
               type="userName"
               :openid="text"></div>
        </template>

        <template #createdTime="{ record }">
          <span>{{ $f.datetime(record.createdTime) }}</span>
        </template>
        <template #action="{record}">
          <a-button type="link"
                    @click="editCorpWord(record.id)">编辑</a-button>
          <a-button type="link"
                    class="ml-16"
                    @click="deleteModalVisible = true; deleteId = record.id ">删除</a-button>
        </template>
      </a-table>
    </div>
    <corp-word-group-edit v-model:visible="editVisible"
                          @created="getList"
                          :group="editGroup">
    </corp-word-group-edit>
    <a-modal v-model:visible="deleteModalVisible"
             title="删除企业话术"
             :confirm-loading="confirmLoading"
             @ok="deleteCorpWord()">

      <p class="text-color-999">企业话术删除后无法恢复，确认是否删除</p>
    </a-modal>

  </div>
</template>

<script>
import _ from "lodash";
import { defineComponent, reactive, ref } from "vue";
import router from "@/router";
import { Image } from "ant-design-vue";
import usePaginationQuery from "@/shared/composables/usePaginationQuery";

import CorpWordGroupEdit from "@/views/customer/corpWord/CorpWordGroupEdit";
import SvgIcon from "@/components/SvgIcon";

import corpWordApi from "@/service/api/corpWord";
import { renderSize, getWxAgentConfig } from "@/global";

export default defineComponent({
  name: "CorpWord",
  components: {
    AImage: Image,
    CorpWordGroupEdit,
    SvgIcon,
  },

  setup() {
    const pdfImgUrl = process.env.VUE_APP_PDF_IMAGE_URL;
    const searchForm = reactive({
      title: "",
    });
    const selectedKeys = ref([]);

    const {
      rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,
    } = usePaginationQuery(router, searchForm, corpWordApi.searchDialogue);

    return {
      pdfImgUrl,
      tableLoading,
      searchForm,
      selectedKeys,
      corpWord: rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
    };
  },

  data() {
    const columns = [
      {
        title: "话术内容",
        dataIndex: "contents",
        width: "220px",
        slots: { customRender: "content" },
      },
      {
        title: "话术标题",
        dataIndex: "title",
        slots: { customRender: "wordTitle" },
      },
      {
        title: "类型",
        dataIndex: "type",
        width: "80px",
      },
      {
        title: "创建人",
        dataIndex: "createdUserWorkWechatThirdAppUserId",
        width: "10%",
        slots: { customRender: "createdUserWorkWechatThirdAppUserId" },
      },
      {
        title: "创建时间",
        dataIndex: "createdTime",
        width: "120px",
        slots: { customRender: "createdTime" },
      },
      {
        title: "发送次数",
        dataIndex: "sendTimes",
        width: "10%",
      },
      {
        title: "操作",
        key: "action",
        slots: { customRender: "action" },
      },
    ];

    const corpWordsGroup = [];

    getWxAgentConfig();

    return {
      renderSize,

      columns,
      corpWordsGroup,
      editGroup: {},
      groupName: "",
      isInputShow: false,
      editVisible: false,
      deleteModalVisible: false,
      confirmLoading: false,
      expandedArr: [],
      deleteId: undefined,
      preImgSrc: "",
    };
  },
  computed: {
    totalCodeGroupNum() {
      let total = 0;
      if (this.corpWordsGroup.length <= 0) {
        return 0;
      }
      _.forEach(this.corpWordsGroup, (value) => {
        total += value.num;
      });
      return total;
    },
  },

  created() {
    this.getList();
    document.addEventListener("click", () => {
      this.isInputShow = false;
    });
  },
  mounted() {},
  methods: {
    // 侧边分组相关方法
    async getList() {
      const res = await corpWordApi.listDetailGroup({ size: 2000 });
      this.corpWordsGroup = res.data;
      this.searchForm.groupId
        ? this.selectedKeys.push(Number(this.searchForm.groupId))
        : (this.selectedKeys = [""]);
    },
    async createCodeGroup() {
      const newCodeGroupName = this.groupName;
      const reg = /^[^\s]*$/;
      if (newCodeGroupName == "") {
        return;
      }
      if (!reg.test(newCodeGroupName)) {
        this.$message.error("不允许包含空格");
        return;
      }
      if (newCodeGroupName.length > 60) {
        this.$message.error("分组名称的长度应为1~60字");
        return;
      }

      await corpWordApi.createGroup({
        name: newCodeGroupName,
      });
      this.$message.success("创建成功");
      await this.getList();
      this.groupName = "";
      this.isInputShow = false;
    },
    async deleteCodeGroup(Group) {
      let { id, num } = Group;
      if (num > 0) {
        this.$message.error("分组还存在话术，无法删除");
        return;
      }

      await corpWordApi.deleteGroup({ id: id });
      this.$message.success("删除成功");
      await this.getList();
    },
    codeGroupClick(res) {
      this.searchForm.groupId = res.key;
      this.onSearchSubmit();
    },

    // 实现话术内容展开相关方法
    clickExpend(index) {
      this.expandedArr[index] = !this.expandedArr[index];
    },
    goLinkUrl(content, type) {
      if (type === "link") {
        window.open(content.link);
      } else if (type === "file") {
        const link =
          "https://" +
          window.location.host +
          process.env.BASE_URL +
          "common/cloudPlayer?no=" +
          content.no +
          "&fileName=" +
          content.name;
        window.open(link);
      }
    },

    // 右侧表格相关方法
    editCorpWord(id) {
      this.$router.push({ name: "corpWord_edit", query: { id: id } });
    },
    async deleteCorpWord() {
      this.confirmLoading = true;
      await corpWordApi.deleteDialogue({ id: this.deleteId });
      this.confirmLoading = false;
      this.$message.success("删除成功");
      await this.getList();
      this.deleteModalVisible = false;
      await this.fetchPaginationData();
    },
  },
});
</script>
<style lang="less" scoped>
.ant-table-wrapper {
  margin-left: @spacing-6x;
  flex: 1;
}
</style>